<template>
  <div>
    <v-dialog
      :value="hasPaper"
      transition="dialog-bottom-transition"
      persistent
      fullscreen
      scrollable
      content-class="receipt-preview"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          text
          :ripple="false"
          color="primary"
          v-bind="attrs"
          v-on="on"
          @click="displayPreview"
        >
          <v-icon left>
            {{ icons.mdiPrinterEye }}
          </v-icon>
          プレビュー
        </v-btn>
      </template>

      <v-card
        tile
        style="background-size: cover; background-position: center;"
        :style="{ backgroundImage: `url(${currentClub.receiptPreviewBackgroundImage})` }"
      >
        <v-app-bar
          flat
          style="background: transparent"
        >
          <v-spacer />
          <v-app-bar-nav-icon>
            <v-btn
              fab
              :ripple="false"
              icon
              large
              @click="deletePreview"
            >
              <v-icon>
                {{ icons.mdiWindowClose }}
              </v-icon>
            </v-btn>
          </v-app-bar-nav-icon>
        </v-app-bar>

        <v-card-text>
          <v-card
            v-if="hasPaper"
            id="paper"
            style="background: transparent; margin: 0 auto;"
            width="400"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  ref,
  nextTick,
} from '@vue/composition-api'
import { mdiPrinterEye, mdiWindowClose } from '@mdi/js'

export default {
  props: {
    slipSvg: {
      type: String,
      default: () => null,
    },
  },
  setup(props) {
    const hasPaper = ref(false)

    const deletePreview = () => {
      const paper = document.getElementById('paper')

      // NOTE: 一度全部削除
      if (paper) {
        while (paper.hasChildNodes()) {
          paper.removeChild(paper.firstChild)
        }
      }

      hasPaper.value = false
    }

    const createSVGDisplayElement = svg => {
      const dom = new DOMParser().parseFromString(svg, 'image/svg+xml').documentElement

      dom.style.width = '100%'
      dom.style.background = 'white'
      dom.style.color = 'black'
      dom.style.height = 'auto'
      dom.style.padding = '7%'

      return dom
    }

    const displayPreview = async () => {
      if (props.slipSvg) {
        const bom = new Uint8Array([0xEF, 0xBB, 0xBF])
        const blobObj = window.URL.createObjectURL(new Blob([bom, props.slipSvg], { type: 'image/svg+xml' }))
        const slipImage = new Image()
        slipImage.src = blobObj

        const receiptDom = createSVGDisplayElement(props.slipSvg)

        hasPaper.value = true
        await nextTick()

        slipImage.onload = () => {
          const paper = document.getElementById('paper')
          paper.appendChild(receiptDom)
        }
      }
    }

    return {
      // data
      hasPaper,

      //  methods
      displayPreview,
      deletePreview,

      icons: {
        mdiPrinterEye,
        mdiWindowClose,
      },
    }
  },
}
</script>

<style lang="scss">
// receiptlineで生成されるsvgのfont-familyを指定
// v-applicationのfont-familyが指定されるとletter-spacingがおかしくなるので
.receipt-preview #paper * {
  font-family: 'Kosugi Maru', 'MS Gothic', 'San Francisco', 'Osaka-Mono', monospace !important;
}
</style>
